import React, { useState, useEffect } from 'react';
import { supabase } from '../../supabaseClient';
import { Edit, Save, X, Plus, Trash } from 'lucide-react';
import './Escales.css';
import './SlidePanel.css';
import EscaleCard from './EscaleCard';

const AddEscaleForm = ({ onSubmit, onClose }) => {
  const [newName, setNewName] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const newEscale = { id: crypto.randomUUID(), name: newName };
      const { error } = await supabase.from('escales').insert([newEscale]);
      if (error) throw error;
      onSubmit();
      setNewName('');
    } catch (error) {
      console.error('Error adding escale:', error);
      alert(`Error adding escale: ${error.message}`);
    }
  };

  return (
    <div className="dashboard-card">
      <form onSubmit={handleSubmit} className="edit-form">
        <input
          value={newName}
          onChange={(e) => setNewName(e.target.value)}
          className="edit-input"
          placeholder="Nom de l'escale"
          required
        />
        <div className="edit-actions">
          <button type="submit" className="save-button">
            <Save size={16} /> Sauvegarder
          </button>
          <button type="button" onClick={onClose} className="cancel-button">
            <X size={16} /> Annuler
          </button>
        </div>
      </form>
    </div>
  );
};

const SlidePanel = ({ isOpen, onClose, children }) => {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (isOpen) {
      // Small delay to ensure transition works
      requestAnimationFrame(() => setIsActive(true));
    } else {
      setIsActive(false);
    }
  }, [isOpen]);

  return (
    <>
      <div 
        className={`slide-panel-overlay ${isActive ? 'active' : ''}`}
        onClick={onClose}
      />
      <div className={`slide-panel ${isActive ? 'active' : ''}`}>
        <div className="slide-panel-header">
          <h2 className="slide-panel-title">Ajouter une escale</h2>
          <button onClick={onClose} className="slide-panel-close">
            <X size={20} />
          </button>
        </div>
        <div className="slide-panel-content">
          {children}
        </div>
      </div>
    </>
  );
};

const Escales = () => {
  const [escales, setEscales] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showAddForm, setShowAddForm] = useState(false);

  const loadEscales = async () => {
    setLoading(true);
    try {
      const { data, error } = await supabase.from('escales').select('*').order('name');
      if (error) throw error;
      setEscales(data);
    } catch (error) {
      console.error('Error loading escales:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadEscales();
  }, []);

  const handleSubmit = () => {
    loadEscales();
    setShowAddForm(false);
  };

  if (loading) return <div>Chargement...</div>;

  return (
    <div>
      <div className="add-section">
        <button onClick={() => setShowAddForm(true)} className="add-button">
          <Plus size={16} /> Ajouter une escale
        </button>
      </div>

      <div className="dashboard-grid">
        {escales.map((escale) => (
          <EscaleCard key={escale.id} escale={escale} onUpdate={loadEscales} />
        ))}
      </div>

      <SlidePanel
        isOpen={showAddForm}
        onClose={() => setShowAddForm(false)}
      >
        <AddEscaleForm
          onSubmit={handleSubmit}
          onClose={() => setShowAddForm(false)}
        />
      </SlidePanel>
    </div>
  );
};

export default Escales;