import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { User, LogOut, Building, Anchor, MapPin, Users } from 'lucide-react';
import { supabase } from '../../supabaseClient';
import './AppSidebar.css';

const AppSidebar = ({ userEmail }) => {
  const navigate = useNavigate();
  const [isAdmin, setIsAdmin] = useState(false);
  const ADMIN_ID = '3e0607aa-51f3-4bfc-b276-c9ebe9328275';

  useEffect(() => {
    const checkUserRole = async () => {
      try {
        const { data: { user } } = await supabase.auth.getUser();
        setIsAdmin(user?.id === ADMIN_ID);
      } catch (error) {
        console.error('Error checking user role:', error.message);
      }
    };

    checkUserRole();
  }, []);

  const handleLogout = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      navigate('/login');
    } catch (error) {
      console.error('Error logging out:', error.message);
    }
  };

  return (
    <div className="app-sidebar">
      <nav className="sidebar-nav">
        <ul className="nav-list">
          {isAdmin && (
            <>
              <li className="nav-item">
                <Link to="/gestion-escales" className="nav-link">
                  <Anchor size={20} className="icon" />
                  <span>Gestion des Escales</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/gestion-villes" className="nav-link">
                  <Building size={20} className="icon" />
                  <span>Gestion des Parcours</span>
                </Link>
              </li>
              <li className="nav-item">
            <Link to="/place-manager" className="nav-link">
              <MapPin size={20} className="icon" />
              <span>Gestion des points d'intérêts</span>
            </Link>
          </li>
              <li className="nav-item">
                <Link to="/user-management" className="nav-link">
                  <Users size={20} className="icon" />
                  <span>Gestion des Utilisateurs</span>
                </Link>
              </li>
            </>
          )}
        
          <li className="nav-item">
          <button onClick={handleLogout} className="logout-button">
        <LogOut size={20} className="icon" />
        <span>Se déconnecter</span>
      </button>
          </li>
        </ul>
      </nav>
     
    </div>
  );
};

export default AppSidebar;