import React, { useState, useEffect, useRef } from 'react';
import { supabase } from '../../supabaseClient';
import { Save, X, Upload, Loader2, Trash2 } from 'lucide-react';
import { v4 as uuidv4 } from 'uuid';
import './SlidePanel.css';

const AddCityForm = ({ onClose, onAddCity, editingCity }) => {
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    escale_id: '',
  });
  const [uploading, setUploading] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [existingImage, setExistingImage] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [escales, setEscales] = useState([]);
  const [loading, setLoading] = useState(false);
  const cityId = editingCity?.id || uuidv4(); // Generate ID early for storage path

  useEffect(() => {
    loadEscales();
    requestAnimationFrame(() => setIsActive(true));
  }, []);

  useEffect(() => {
    if (editingCity) {
      setFormData({
        name: editingCity.name || '',
        description: editingCity.description || '',
        escale_id: editingCity.escale_id || ''
      });
      if (editingCity.image_url) {
        setExistingImage(editingCity.image_url);
      }
    }
  }, [editingCity]);

  const loadEscales = async () => {
    setLoading(true);
    try {
      const { data, error } = await supabase
        .from('escales')
        .select('*')
        .order('name');
      if (error) throw error;
      setEscales(data);
    } catch (error) {
      console.error('Error loading escales:', error);
    } finally {
      setLoading(false);
    }
  };
const uploadImage = async (file) => {
  const fileExt = file.name.split('.').pop();
  const fileName = `${uuidv4()}.${fileExt}`;
  // Store in city-specific folder
  const filePath = `${cityId}/${fileName}`;

  const { error: uploadError } = await supabase.storage
    .from('places_media')  // Changed from 'city-images' to 'places_media'
    .upload(filePath, file);

  if (uploadError) throw uploadError;

  const { data: { publicUrl } } = supabase.storage
    .from('places_media')  // Changed from 'city-images' to 'places_media'
    .getPublicUrl(filePath);

  return publicUrl;
};

const deleteExistingImage = async () => {
  if (!existingImage) return;
  
  try {
    // Extract the path from the URL
    const urlParts = existingImage.split('/');
    const fileName = urlParts[urlParts.length - 1].split('?')[0];
    const path = `${cityId}/${fileName}`;
    
    const { error } = await supabase.storage
      .from('places_media')  // Changed from 'city-images' to 'places_media'
      .remove([path]);

    if (error) throw error;
    setExistingImage(null);
  } catch (error) {
    console.error('Error deleting image:', error);
    throw error;
  }
};
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      setExistingImage(null);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.escale_id) {
      alert('Please select an escale');
      return;
    }
    
    setUploading(true);
    try {
      let imageUrl = existingImage;

      if (imageFile) {
        if (existingImage) {
          await deleteExistingImage();
        }
        imageUrl = await uploadImage(imageFile);
      }

      const cityData = {
        ...formData,
        id: cityId,
        image_url: imageUrl,
        updated_at: new Date().toISOString()
      };

      if (editingCity) {
        const { error } = await supabase
          .from('cities')
          .update(cityData)
          .eq('id', editingCity.id);

        if (error) throw error;
      } else {
        const { error } = await supabase
          .from('cities')
          .insert([{
            ...cityData,
            created_at: new Date().toISOString()
          }]);

        if (error) throw error;
      }

      onAddCity();
      onClose();
    } catch (error) {
      console.error('Error saving city:', error);
      alert('Error saving city: ' + error.message);
    } finally {
      setUploading(false);
    }
  };

  return (
    <>
      <div 
        className={`slide-panel-overlay ${isActive ? 'active' : ''}`}
        onClick={() => onClose()}
      />
      <div className={`slide-panel ${isActive ? 'active' : ''}`}>
        <div className="slide-panel-header">
          <h2 className="slide-panel-title">
            {editingCity ? 'Modifier le parcours' : 'Ajouter un parcours'}
          </h2>
          <button onClick={() => onClose()} className="slide-panel-close">
            <X size={20} />
          </button>
        </div>

        <div className="slide-panel-content">
          <form onSubmit={handleSubmit} className="edit-form">
            <div className="form-group">
              <label className="form-label">Nom du parcours *</label>
              <input
                value={formData.name}
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                className="edit-input"
                placeholder="Entrez le nom du parcours"
                maxLength={25}
                required
              />
            </div>

            <div className="form-group">
              <label className="form-label">Description *</label>
              <textarea
                value={formData.description}
                onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                className="edit-textarea"
                placeholder="Entrez la description"
                maxLength={75}
                required
              />
            </div>

            <div className="form-group">
              <label className="form-label">Escale *</label>
              <select
                value={formData.escale_id}
                onChange={(e) => setFormData({ ...formData, escale_id: e.target.value })}
                className="edit-input"
                required
              >
                <option value="">Sélectionnez une escale</option>
                {escales.map((escale) => (
                  <option key={escale.id} value={escale.id}>
                    {escale.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <label className="form-label">Image du parcours *</label>
              <div className="image-upload-container">
                {(imagePreview || existingImage) ? (
                  <div className="image-preview">
                    <img
                      src={imagePreview || existingImage}
                      alt="Aperçu"
                      className="preview-image"
                    />
                    <button
                      type="button"
                      onClick={() => {
                        setImagePreview(null);
                        setImageFile(null);
                        if (existingImage) setExistingImage(null);
                      }}
                      className="remove-image"
                    >
                      <Trash2 size={16} />
                    </button>
                  </div>
                ) : (
                  <label className="upload-label">
                    <Upload size={24} />
                    <span>Télécharger une image</span>
                    <input
                      type="file"
                      className="hidden"
                      accept="image/*"
                      onChange={handleImageChange}
                      required={!editingCity && !existingImage}
                    />
                  </label>
                )}
              </div>
            </div>

            <div className="edit-actions">
              <button
                type="submit"
                className="save-button"
                disabled={uploading}
              >
                {uploading ? (
                  <>
                    <Loader2 className="animate-spin" size={16} />
                    <span>{editingCity ? 'Mise à jour...' : 'Création...'}</span>
                  </>
                ) : (
                  <>
                    <Save size={16} />
                    <span>{editingCity ? 'Mettre à jour' : 'Enregistrer'}</span>
                  </>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddCityForm;
