import React, { useState, useEffect } from 'react';
import { supabase } from '../../supabaseClient';
import { Plus, Loader2 } from 'lucide-react';
import './CityManager.css';
import AddCityForm from './AddCityForm';
import CityCard from './CityCard';

const CityManager = () => {
  const [cities, setCities] = useState([]);
  const [userCities, setUserCities] = useState([]);
  const [isAdmin, setIsAdmin] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showAddForm, setShowAddForm] = useState(false);
  const [formPosition, setFormPosition] = useState({ x: 0, y: 0 });
  const [editingCity, setEditingCity] = useState(null);

  useEffect(() => {
    fetchAdminStatus();
  }, []);

  useEffect(() => {
    if (isAdmin !== null) fetchCities();
  }, [isAdmin]);

  const fetchAdminStatus = async () => {
    const { data: { user } } = await supabase.auth.getUser();
    setIsAdmin(user?.id === '3e0607aa-51f3-4bfc-b276-c9ebe9328275');
  };

  const fetchCities = async () => {
    setLoading(true);
    try {
      const { data: { user } } = await supabase.auth.getUser();
      const query = isAdmin
        ? supabase.from('cities').select('*').order('name')
        : supabase.from('city_managers').select('cities (*)').eq('user_id', user.id);
      
      const { data, error } = await query;
      if (error) throw error;
      
      if (isAdmin) {
        setCities(data);
      } else {
        const processedCities = data.map(item => item.cities).filter(Boolean);
        setUserCities(processedCities);
      }
    } catch (error) {
      console.error('Error loading cities:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleAddClick = (e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    setFormPosition({
      x: rect.left + window.scrollX,
      y: rect.bottom + window.scrollY + 10
    });
    setShowAddForm(true);
  };

  const handleCloseForm = () => {
    setShowAddForm(false);
    setEditingCity(null);
  };

  const handleEditCity = (city) => {
    setEditingCity(city);
    setShowAddForm(true);
  };

  if (loading) {
    return (
      <div className="loading-container">
        <Loader2 className="loading-spinner" />
        <span>Chargement...</span>
      </div>
    );
  }

  return (
    <div className="city-manager">
      {isAdmin && (
        <div className="add-city-section">
          <button 
            onClick={handleAddClick} 
            className="add-city-button"
          >
            <Plus size={16} /> Ajouter un parcours
          </button>
        </div>
      )}

      <div className="dashboard-grid">
        {(isAdmin ? cities : userCities).map(city => (
          <CityCard 
            key={city.id} 
            city={city} 
            onUpdate={fetchCities}
            onEdit={() => handleEditCity(city)}
            isAdmin={isAdmin}
          />
        ))}
      </div>

      {showAddForm && (
        <AddCityForm
          onClose={handleCloseForm}
          onAddCity={fetchCities}
          editingCity={editingCity}
          position={formPosition}
        />
      )}
    </div>
  );
};

export default CityManager;