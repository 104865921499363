import React, { useState, useEffect } from 'react';
import { supabase } from '../../supabaseClient';
import { X, Plus, Save, ArrowLeft, Trash } from 'lucide-react';
import './UserManager.css';

const UserForm = ({ onSubmit, onClose }) => {
  const [cities, setCities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    role: '',
    city_id: ''
  });

  useEffect(() => {
    fetchCities();
  }, []);

  const fetchCities = async () => {
    try {
      const { data, error } = await supabase
        .from('cities')
        .select('id, name');
      if (error) throw error;
      setCities(data || []);
    } catch (error) {
      console.error('Error fetching cities:', error);
      setError('Error loading cities');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.email || !formData.password || !formData.role) {
      setError('Please fill in all required fields');
      return;
    }

    setLoading(true);
    try {
      const { data: authData, error: authError } = await supabase.auth.signUp({
        email: formData.email,
        password: formData.password,
      });
      if (authError) throw authError;

      const { error: profileError } = await supabase
        .from('profiles')
        .insert([{
          id: authData.user.id,
          email: formData.email,
          role: formData.role,
          city_id: formData.city_id || null
        }]);
      if (profileError) throw profileError;

      onSubmit();
    } catch (error) {
      console.error('Error creating user:', error);
      setError(`Error creating user: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="user-form">
      {error && <div className="error-message">{error}</div>}
      
      <div className="form-group">
        <label>Email: *</label>
        <input
          type="email"
          value={formData.email}
          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
          required
          placeholder="Entrez un email"
        />
      </div>

      <div className="form-group">
        <label>Mot de passe: *</label>
        <input
          type="password"
          value={formData.password}
          onChange={(e) => setFormData({ ...formData, password: e.target.value })}
          required
          placeholder="Entrez un mot de passe"
        />
      </div>

      <div className="form-group">
        <label>Rôle: *</label>
        <select
          value={formData.role}
          onChange={(e) => setFormData({ ...formData, role: e.target.value })}
          required
        >
          <option value="">-- Sélectionnez un rôle --</option>
          <option value="basic">Basique</option>
          <option value="admin">Admin</option>
        </select>
      </div>

      <div className="form-group">
        <label>Parcours:</label>
        <select
          value={formData.city_id}
          onChange={(e) => setFormData({ ...formData, city_id: e.target.value })}
        >
          <option value="">-- Sélectionner un parcours --</option>
          {cities.map((city) => (
            <option key={city.id} value={city.id}>{city.name}</option>
          ))}
        </select>
      </div>

      <div className="button-group">
        <button
          type="button"
          onClick={onClose}
          className="cancel-button"
        >
          <X size={16} />
          <span>Annuler</span>
        </button>
        <button
          type="submit"
          className="save-button"
          disabled={loading}
        >
          <Save size={16} />
          <span>{loading ? 'Creation...' : 'Créer un utilisateur'}</span>
        </button>
      </div>
    </form>
  );
};

const SlidePanel = ({ isOpen, onClose, children }) => {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (isOpen) {
      requestAnimationFrame(() => setIsActive(true));
    } else {
      setIsActive(false);
    }
  }, [isOpen]);

  return (
    <>
      <div 
        className={`slide-panel-overlay ${isActive ? 'active' : ''}`}
        onClick={onClose}
      />
      <div className={`slide-panel ${isActive ? 'active' : ''}`}>
        <div className="slide-panel-header">
          <h2 className="slide-panel-title">Ajouter un utilisateur</h2>
          <button onClick={onClose} className="slide-panel-close">
            <X size={20} />
          </button>
        </div>
        <div className="slide-panel-content">
          {children}
        </div>
      </div>
    </>
  );
};

const UserManager = () => {
  const [users, setUsers] = useState([]);
  const [cities, setCities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    fetchUsers();
    fetchCities();
  }, []);

  const fetchUsers = async () => {
    try {
      const { data, error } = await supabase
        .from('profiles')
        .select('id, email, role, city_id');
      if (error) throw error;
      setUsers(data || []);
    } catch (error) {
      console.error('Error fetching users:', error);
      setError('Error loading users');
    } finally {
      setLoading(false);
    }
  };

  const fetchCities = async () => {
    try {
      const { data, error } = await supabase
        .from('cities')
        .select('id, name');
      if (error) throw error;
      setCities(data || []);
    } catch (error) {
      console.error('Error fetching cities:', error);
    }
  };

  const updateUserRole = async (userId, newRole) => {
    try {
      const { error } = await supabase
        .from('profiles')
        .update({ role: newRole })
        .eq('id', userId);
      if (error) throw error;
      fetchUsers();
    } catch (error) {
      console.error('Error updating user role:', error);
      setError(`Error updating role: ${error.message}`);
    }
  };

  const deleteUser = async (userId) => {
    if (!window.confirm('Are you sure you want to delete this user?')) {
      return;
    }
    try {
      const { error } = await supabase
        .from('profiles')
        .delete()
        .eq('id', userId);
      if (error) throw error;
      fetchUsers();
    } catch (error) {
      console.error('Error deleting user:', error);
      setError(`Error deleting user: ${error.message}`);
    }
  };

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <div className="place-manager">
      <div className="place-manager-header">
        <h2>Gestion des utilisateurs</h2>
        <button
          onClick={() => setShowForm(true)}
          className="add-place-button"
        >
          <Plus size={16} />
          <span>Ajouter un utilisateur</span>
        </button>
      </div>

      {error && <div className="error-message">{error}</div>}

      <table className="users-list">
        <thead>
          <tr>
            <th>Email</th>
            <th>Rôle</th>
            <th>Parcours</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user.id}>
              <td>{user.email}</td>
              <td>
                <select
                  value={user.role}
                  onChange={(e) => updateUserRole(user.id, e.target.value)}
                >
                  <option value="basic">Basic</option>
                  <option value="admin">Admin</option>
                </select>
              </td>
              <td>
                <select
                  value={user.city_id || ''}
                  onChange={(e) => {
                    const updateCity = async () => {
                      const { error } = await supabase
                        .from('profiles')
                        .update({ city_id: e.target.value || null })
                        .eq('id', user.id);
                      if (error) {
                        console.error('Error updating city:', error);
                        setError('Error updating city');
                      } else {
                        fetchUsers();
                      }
                    };
                    updateCity();
                  }}
                >
                  <option value="">-- Aucune ville --</option>
                  {cities.map((city) => (
                    <option key={city.id} value={city.id}>{city.name}</option>
                  ))}
                </select>
              </td>
              <td>
                <button
                  onClick={() => deleteUser(user.id)}
                  className="delete-button"
                >
                  <Trash size={16} />
                  <span>Supprimer</span>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <SlidePanel
        isOpen={showForm}
        onClose={() => {
          setShowForm(false);
          setError(null);
        }}
      >
        <UserForm
          onSubmit={() => {
            fetchUsers();
            setShowForm(false);
          }}
          onClose={() => {
            setShowForm(false);
            setError(null);
          }}
        />
      </SlidePanel>
    </div>
  );
};

export default UserManager;