import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import AppSidebar from './components/AppSidebar/AppSidebar';
import LoginPage from './components/LoginPage/LoginPage';
import Dashboard from './components/Dashboard/Dashboard';
import CityManager from './components/CityManager/CityManager';
import Escales from './components/Escales/Escales';
import PlaceEditor from './components/PlaceEditor/PlaceEditor';
import PlaceManager from './components/PlaceManager/PlaceManager';
import LandingPage from './components/Landing/LandingPage'; // Make sure to import your landing page component
import './App.css';
import UserManager from './components/UserManager/UserManager';

// Layout component that conditionally renders the sidebar
const AppLayout = ({ children }) => {
  const location = useLocation();
  const isPublicPage = location.pathname === '/login' || location.pathname === '/';

  if (isPublicPage) {
    return children;
  }

  return (
    <div className="app-container">
      <AppSidebar />
      <div className="main-content">
        {children}
      </div>
    </div>
  );
};

function App() {
  return (
    <Router>
      <AppLayout>
        <Routes>
          {/* Public routes */}
          <Route path="/" element={<LoginPage />} />
          
          
          {/* Protected routes */}
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/place-manager" element={<PlaceManager />} />
          <Route path="/edit-place/:placeId" element={<PlaceEditor />} />
          <Route path="/gestion-villes" element={<CityManager />} />
          <Route path="/gestion-escales" element={<Escales />} />
          <Route path="/user-management" element={<UserManager />} />         
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </AppLayout>
    </Router>
  );
}

export default App;